import { Button } from "@fluentui/react-components";
import React from "react";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const Forbidden = (props) => {

    const backToHome = () => {
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_LOCAL;
    }

    return (
        <div id="home-page">
            <h5>
                <center>
                    Access to the requested resource is forbidden
                    <br />
                    <Button onClick={backToHome}>
                        Back to homepage
                    </Button>
                </center>

            </h5>
        </div>
    );
};