import React, { useEffect, useState } from "react";
import { CounterBadge, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, PresenceBadge, makeStyles, tokens } from "@fluentui/react-components";
import { NavLink } from "react-router-dom";
import "./nav-header.scss";
import { Home16Regular, Box16Regular, Alert24Regular, DocumentData16Regular, PeopleTeam16Regular } from "@fluentui/react-icons";
import { UserAvatar } from "../avatar/user-avatar";
import { useMsal } from "@azure/msal-react";
import API from "../../common/API";
import URL_PATH from "../../common/URL_PATH";
import Common from "../../common/Common";

/**
 * Header components for website
 * @param props 
 */

const useStyles = makeStyles({
    header: {
        backgroundColor: tokens.colorBrandStroke1,
        display: "flex",
        justifyContent: "center",
        position: "fixed",
        width: "100%",
        top: "0px",
        zIndex: "1000",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "1200px",
    },
    leftPanel: {
        display: "flex",
        alignSelf: "baseline",
    },
    rightPanel: {
        display: "flex",
        alignSelf: "baseline",
        height: "100%",
        alignItems: "center",
        columnGap: "8px"
    },
    link: {
        paddingLeft: "8px",
        paddingTop: "16px",
        paddingBottom: "16px",
        paddingRight: "8px",
        fontWeight: 'bold',
        display: "flex",
        alignSelf: "baseline",
        alignItems: "center",
        color: tokens.colorBrandStroke2,
        "&>*": {
            marginRight: "4px"
        },
        ":hover": {
            backgroundColor: tokens.colorCompoundBrandStrokeHover,
            color: tokens.colorBrandStroke2
        },
    },
    linkBell: {
        paddingTop: "16px",
        paddingBottom: "16px",
        paddingLeft: "4px",
        paddingRight: "4px",
        fontWeight: 'bold',
        display: "flex",
        alignSelf: "baseline",
        alignItems: "center",
        color: tokens.colorBrandStroke2,
        ":hover": {
            backgroundColor: tokens.colorCompoundBrandStrokeHover,
            color: tokens.colorBrandStroke2
        },
    },
    avatar: {
        ":hover": {
            cursor: "pointer"
        }
    }
});

const data = ["available", "busy", "out-of-office"]

export const NavHeader = (props) => {
    const styles = useStyles();
    const [user, setUser] = useState({});

    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    
    const changeStatus = async (target_status) => {
        let data = {
			status: target_status
		};
       let response = await API.post(URL_PATH.postUpdateStatus, data);
       setUser(response);
    }

    useEffect(() => {
        (async function () {
            try {
                const response = await API.get(URL_PATH.getCurrentUser);
                setUser(response);
            } catch (e) {
                console.error(e);
            }
        })();

    }, []);

    return (
        <div className={"header " + styles.header}>
            <div className={styles.container}>
                <div className={styles.leftPanel}>
                    <NavLink to={"/"} className={styles.link}><Home16Regular /> Home</NavLink>
                    <NavLink to={"/project-list"} className={styles.link}><Box16Regular /> Project</NavLink>
                    <NavLink to={"/master-data"} className={styles.link}><DocumentData16Regular /> Master Data</NavLink>
                    <NavLink to={"/expert"} className={styles.link}><PeopleTeam16Regular /> Expert</NavLink>
                    <NavLink to={"/guideline"} className={styles.link}><PeopleTeam16Regular /> Guideline</NavLink>
                </div>
                <div className={styles.rightPanel}>
                    <div className={styles.linkBell}>
                        <Alert24Regular />
                        <CounterBadge size="small" appearance="filled" color="danger" count={0} />
                    </div>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton menuIcon={<PresenceBadge size="small" status={user.status} />} shape="circular"> {Common.getNameStatus(user.status)}</MenuButton>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                {data.map((item) =>
                                    <MenuItem key={item} onClick={() => changeStatus(item)} icon={<PresenceBadge size="small" status={item} />}> {Common.getNameStatus(item)}</MenuItem>
                                )}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <div className={styles.avatar}><UserAvatar id={user.id} displayName={user.displayName} status={user.status}/></div>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem>Profile </MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>

                </div>
            </div>
        </div>
    );
};