import React from 'react';
import { PageLayout } from './components/page-layout/page-layout';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { HomePage } from './pages/home-page/home-page';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { ProjectList } from './pages/project-list/project-list';
import { MainPage } from './pages/main-page/main-page';
import { MainLayout } from './components/page-layout/main-layout';
import { ProjectDetail } from './pages/project-detail/project-detail';
import { GenerateEstimate } from './pages/generate-estimate/generate-estimate';
import { ProjectLayout } from './components/page-layout/project-layout';
import { MasterData } from './pages/master-data/master-data';
import { TaskNLogwork } from './pages/project-detail/task-logwork';
import { EstimationPlan } from './pages/project-detail/est-plan';
import { History } from './pages/project-detail/history';
import { Solution } from './pages/project-detail/solution';
import { RiskNIssue } from './pages/project-detail/risk-issue';
import { Tool } from './pages/project-detail/tool';
import { QuestionNAnswer } from './pages/project-detail/qna';
import { License } from './pages/project-detail/license';
import { WorkingFile } from './pages/project-detail/working-file';
import { ReferenceOldEstimation } from './pages/project-detail/ref-old-est';
import { ServiceOffering } from './pages/project-detail/service-offering';
import { Template } from './pages/project-detail/template';
import { CaseStudy } from './pages/project-detail/case-study';
import { Unauthorized } from './pages/error/unauthorized';
import { Forbidden } from './pages/error/forbidden';
import { Expert } from './pages/expert/expert';

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */

const router = createBrowserRouter([
    {
        path: "/forbidden",
        element: <Forbidden />,

    },
    {
        element: <MainLayout />,
        children: [
            {
                path: "/",
                element: <MainPage />,
            },
            {
                path: "/project-list",
                element: <ProjectList />,
            },
            {
                path: "/master-data",
                element: <MasterData />,
            },
            {
                path: "/expert",
                element: <Expert />,
            }
        ]
    },
    {
        element: <PageLayout />,
        children: [
            {
                path: "/project/:id/generate",
                element: <GenerateEstimate />
            }
        ]
    },
    {
        element: <ProjectLayout />,
        children: [
            {
                path: "/project/:id/general",
                element: <ProjectDetail />
            },
            {
                path: "/project/:id/task-logwork",
                element: <TaskNLogwork />
            },
            {
                path: "/project/:id/est-plan",
                element: <EstimationPlan />
            },
            {
                path: "/project/:id/history",
                element: <History />
            },
            {
                path: "/project/:id/case-study",
                element: <CaseStudy />
            },
            {
                path: "/project/:id/solution",
                element: <Solution />
            },
            {
                path: "/project/:id/risk-issue",
                element: <RiskNIssue />
            },
            {
                path: "/project/:id/tool",
                element: <Tool />
            },
            {
                path: "/project/:id/qna",
                element: <QuestionNAnswer />
            },
            {
                path: "/project/:id/license",
                element: <License />
            },
            {
                path: "/project/:id/working-file",
                element: <WorkingFile />
            },
            {
                path: "/project/:id/ref-old-est",
                element: <ReferenceOldEstimation />
            },
            {
                path: "/project/:id/service-offering",
                element: <ServiceOffering />
            },
            {
                path: "/project/:id/template",
                element: <Template />
            }
        ]
    },
    {
        path: "*",
        element: <Unauthorized />,

    }
]);

const MainContent = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <RouterProvider router={router} />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <HomePage />
            </UnauthenticatedTemplate>
        </>
    );
};

export default function App() {
    return (
        <MainContent />
    );
}
