export function SendApi(url, method, data, contentType) {

    const headers = new Headers({
        'Content-Type': contentType
    });
    const gateToken = sessionStorage.getItem('token');
    if (gateToken !== null) {
        headers.append('Authorization', 'Bearer ' + gateToken);
    }
    return fetch(process.env.REACT_APP_API_HOST + url, {
        method: method,
        headers: headers,
        body: contentType === 'application/json' ? JSON.stringify(data) : data
    });
}

export async function get(url, params = []) {
    let response = await SendApi(FormatString(url, params), 'GET', null, null);
    if (response.status === 200) {
        const json = await response.json();
        return json;
    } else if (response.status === 403) {
        window.location.href = '/forbidden';
    } else if (response.status === 401) {
        window.location.href = '/';
    }
}

export async function post(url, data) {
    let response = await SendApi(url, 'POST', data, 'application/json');
    if (response.status === 200) {
        const json = await response.json();
        return json;
    } else if (response.status === 403) {
        window.location.href = '/forbidden';
    } else if (response.status === 401) {
        window.location.href = '/';
    }
}

export function FormatString(source, params) {
    params.forEach(function (value, index) {
        source = source.replace(new RegExp("\\{" + index + "\\}", "g"), value);
    });
    return source;
}

const API = {
    get: get,
    post: post
}
export default API;