import { makeResetStyles, makeStyles, shorthands } from "@fluentui/react-components";
import React, { useState } from "react";
import { Editable } from "../../components/editable/editable";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */

const useStyles = makeStyles({
    content: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        ...shorthands.padding("8px"),
    },
    formGeneral: {
    },
});

const useStackClassName = makeResetStyles({
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "33% 33% 33%",
    '@media (max-width: 1080px)': {
        gridTemplateColumns: "100%",
    },
});
export const ProjectDetail = (props) => {
    const styles = useStyles();
    const [projectName, setProjectName] = useState("Logistic");
    const [estVer, setEstVer] = useState("1.0");
    const [customer, setCustomer] = useState("Meica");
    const [pic, setPic] = useState("Hau Len Van");
    const [approver, setApprover] = useState("Tuan Nguyen Minh");
    const [description, setDescription] = useState("Chuyen doi A sang B");

    return (
        <div className={styles.content}>
            <h5>
                General
            </h5>
            <div className={styles.formGeneral} >
                <div className={useStackClassName()}>
                    <Editable label="Project Name:" value={projectName} onEdited={(val) => setProjectName(val)} hint={"Name of the project"} />
                    <Editable label="Estimate Version:" value={estVer} onEdited={(val) => setEstVer(val)} hint={"Version of current estimation."} />
                    <Editable label="Customer:" value={customer} onEdited={(val) => setCustomer(val)} hint={"Partner company"} />
                    <Editable label="Main PIC:" value={pic} onEdited={(val) => setPic(val)} hint={"Person responsible for the estimation."} />
                    <Editable label="Approver:" value={approver} onEdited={(val) => setApprover(val)} hint={"The person agrees with the method and amount of the estimation."} />
                    <Editable label="Description:" value={description} onEdited={(val) => setDescription(val)} hint={"Brief description of the project"} />
                </div>
            </div>
        </div>
    );
};