import { makeStyles, shorthands } from "@fluentui/react-components";
import React from "react";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
const useStyles = makeStyles({
    content: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        ...shorthands.padding("8px"),
    },
    formGeneral: {
    },
});
export const History = (props) => {
    const styles = useStyles();
    return (
        <div className={styles.content}>
            <h5>
                <center>History</center>
            </h5>
        </div>
    );
};