import React from "react";
import { Avatar } from "@fluentui/react-components";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const UserAvatar = ({ id, displayName, status }) => {
    return (
        <Avatar color="colorful" name={displayName} badge={{ status: status }} />
    )
}