import React from "react";
import { SignInButton } from "../../components/sign-in-button/sign-in-button";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const HomePage = (props) => {
    return (
        <div id="home-page">
            <h5>
                <center>
                    Chào mừng bạn đến với hệ thống FLT.Power
                    <br />
                    <SignInButton />
                </center>
            </h5>
        </div>
    );
};