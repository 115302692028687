import { useMsal } from "@azure/msal-react";
import React, { Fragment, useEffect, useState } from "react";
import API from "../../common/API";
import URL_PATH from "../../common/URL_PATH";
import { Body1, Button, Caption1, Card, CardFooter, CardHeader, Divider, Field, InlineDrawer, SkeletonItem, Text, Textarea, makeStyles, shorthands, useRestoreFocusTarget } from "@fluentui/react-components";
import { ArrowReplyRegular, ShareRegular, MoreHorizontal20Regular } from "@fluentui/react-icons";
import { UserAvatar } from "../../components/avatar/user-avatar";
import { UserAvatarStatus } from "../../components/avatar/user-avatar-status";
import { Reaction } from "../../components/reaction/reaction";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        backgroundColor: "#fff",
        marginTop: "8px"
    },
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    card: {
        marginBottom: "4px",
        width: "100%",
    },
    listUser: {
        display: "grid",
        gridAutoFlow: "row",
        gridTemplateColumns: "repeat(1, max-content)",
        columnGap: "16px",
        rowGap: "8px",
    },
    listUserSklt: {
        display: "flex",
        gridAutoFlow: "row",
        gridTemplateColumns: "repeat(1, max-content)",
        flexWrap: "wrap",
        rowGap: "8px",
    },
    row: {
        display: "flex",
        width: "100%",
        columnGap: "8px",
    },
    section: {
        marginLeft: "30px"
    },
    text: {
        marginLeft: "42px"
    }
});
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const MainPage = (props) => {
    const { instance, accounts } = useMsal();
    const [data, setData] = useState([]);
    const styles = useStyles();
    const restoreFocusTargetAttribute = useRestoreFocusTarget();

    useEffect(() => {
        if (accounts.length > 0) {
            // User is logged in, acquire token silently
            const account = accounts[0];
            instance.acquireTokenSilent({ scopes: [`api://46b9b03c-1776-4bb1-b414-ef4c97df15a4/Power.Read`], account })
                .then(response => {
                    sessionStorage.setItem('token', response.accessToken);
                })
                .catch(error => {
                    console.error('Token acquisition error:', error);
                });
        }

        (async function () {
            try {
                const response = await API.get(URL_PATH.getAllUser);
                setData(response);
            } catch (e) {
                console.error(e);
            }
        })();

    }, [accounts, instance]);


    const divs = Array.from({ length: 5 }, (_, index) => (
        <div className={styles.row}>
            <div>
                <SkeletonItem shape="circle" size={32} />
            </div>
            <SkeletonItem size={32} />
        </div>
    ));

    return (
        <Fragment>
            <div className={styles.root}>
                <InlineDrawer separator open>
                    {(data.length === 0) ?
                        <div className={styles.listUserSklt}>
                            {divs}
                        </div>
                        :
                        <div className={styles.listUser}>
                            {data.map((item) =>
                                <UserAvatarStatus key={item.id} id={item.id} displayName={item.displayName} status={item.status} />
                            )}
                        </div>
                    }
                </InlineDrawer>
                <div className={styles.content}>
                    <div>
                        {data.map((item) => (
                            <Card className={styles.card}>
                                <CardHeader
                                    image={
                                        <UserAvatar
                                            id={item.id}
                                            displayName={item.displayName}
                                            status={item.status}
                                        />
                                    }
                                    header={
                                        <Body1>
                                            <b>{item.displayName}</b> create estimate
                                        </Body1>
                                    }
                                    description={<Caption1>5h ago · About us - Update</Caption1>}
                                />
                                <CardFooter>
                                    <Reaction />
                                    <Button appearance="subtle" icon={<ArrowReplyRegular fontSize={16} />}>Reply</Button>
                                    <Button appearance="subtle" icon={<ShareRegular fontSize={16} />}>Share</Button>
                                </CardFooter>
                                <Divider />
                                <section className={styles.section}>
                                    {Array.from({ length: 3 }, (_, index) => {
                                        let element = data[Math.floor(Math.random() * data.length)];
                                        console.log(element);
                                        return <div className={styles.card} >
                                            <CardHeader
                                                image={
                                                    <UserAvatar id={1} displayName={element.displayName} status={element.status} />
                                                }
                                                header={<Text weight="semibold">{element.displayName}</Text>}
                                                description={
                                                    <Caption1 className={styles.caption}>3h ago</Caption1>
                                                }
                                                action={
                                                    <Button
                                                        appearance="transparent"
                                                        icon={<MoreHorizontal20Regular />}
                                                        aria-label="More options"
                                                    />
                                                }
                                            />
                                            <p className={styles.text}>
                                                Donut chocolate bar oat cake. Dragée tiramisu lollipop bear claw.
                                                Marshmallow pastry jujubes toffee sugar plum.
                                                <br />
                                                <Reaction />
                                            </p>
                                        </div>
                                    }
                                    )
                                    }
                                    <Field label="Compose message" className={styles.field}>
                                        <Textarea />
                                    </Field>
                                    <br />
                                    <Button {...restoreFocusTargetAttribute}>Send message</Button>
                                </section>
                            </Card>
                        ))
                        }
                    </div>
                </div>
            </div>
        </Fragment >
    );
};