const Common = {
    getNameStatus: (code) => {
        if (code === "available") {
            return "Can take on more work"
        }
        if (code === "busy") {
            return "Busy"
        }
        if (code === "out-of-office") {
            return "Not willing to take on more work"
        }
        if (code === "blocked") {
            return "Access is not allowed"
        }
    }
}
export default Common;