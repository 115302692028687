import * as React from "react";
import {
    Home16Regular, DocumentData16Regular,
    FolderPerson16Regular,
    Calendar16Regular,
    Notebook16Regular,
    FlashCheckmark16Regular,
    TextBulletListSquareWarning16Regular,
    WindowDevTools16Regular,
    ChatBubblesQuestion16Regular,
    Ribbon16Regular,
    DocumentOnePageSparkle16Regular,
    DocumentTable16Regular,
    History16Filled,
    TaskListSquareLtr16Regular,
    Toolbox16Regular
} from "@fluentui/react-icons";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbButton,
    BreadcrumbDivider,
    InlineDrawer,
    makeStyles,
    shorthands,
    MenuList,
    MenuItemRadio
} from "@fluentui/react-components";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        backgroundColor: "#fff",
    },
    content: {
        ...shorthands.flex(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    }
});

export const MasterData = () => {
    const styles = useStyles();
    const [checkedValues, setCheckedValues] = React.useState({ dataItem: ["default-task"] });
    const onChange = (
        e,
        { name, checkedItems }
    ) => {
        setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
    };
    return (
        <>
            <Breadcrumb aria-label="Breadcrumb default example">
                <BreadcrumbItem>
                    <BreadcrumbButton icon={<Home16Regular />}>
                        <Link to="/">Home</Link>
                    </BreadcrumbButton>
                </BreadcrumbItem>
                <BreadcrumbDivider />
                <BreadcrumbItem>
                    <BreadcrumbButton icon={<DocumentData16Regular />}>
                        <Link to="/master-data">Master Data</Link>
                    </BreadcrumbButton>
                </BreadcrumbItem>
            </Breadcrumb>
            <div className={styles.root}>
                <InlineDrawer separator open>
                    <MenuList checkedValues={checkedValues} onCheckedValueChange={onChange}>
                        <MenuItemRadio icon={<TaskListSquareLtr16Regular />} name="dataItem" value="default-task">
                            Default Task
                        </MenuItemRadio>
                        <MenuItemRadio icon={<Calendar16Regular />} name="dataItem" value="estimation-plan">
                            Estimation plan
                        </MenuItemRadio>
                        <MenuItemRadio icon={<History16Filled />} name="dataItem" value="version-history">
                            Version History
                        </MenuItemRadio>
                        <MenuItemRadio icon={<Notebook16Regular />} name="dataItem" value="case-study">
                            Case Study
                        </MenuItemRadio>
                        <MenuItemRadio icon={<FlashCheckmark16Regular />} name="dataItem" value="solution">
                            Solution
                        </MenuItemRadio>
                        <MenuItemRadio icon={<TextBulletListSquareWarning16Regular />} name="dataItem" value="risknissue">
                            Risk & Issue
                        </MenuItemRadio>
                        <MenuItemRadio icon={<WindowDevTools16Regular />} name="dataItem" value="tool-support">
                            Tool Support
                        </MenuItemRadio>
                        <MenuItemRadio icon={<ChatBubblesQuestion16Regular />} name="dataItem" value="qna">
                            Q&A
                        </MenuItemRadio>
                        <MenuItemRadio icon={<Ribbon16Regular />} name="dataItem" value="license-pool">
                            License Pool
                        </MenuItemRadio>
                        <MenuItemRadio icon={<DocumentOnePageSparkle16Regular />} name="dataItem" value="service-offering">
                            Service Offering
                        </MenuItemRadio>
                        <MenuItemRadio icon={<DocumentTable16Regular />} name="dataItem" value="template">
                            Template
                        </MenuItemRadio>
                        <MenuItemRadio icon={<FolderPerson16Regular />} name="dataItem" value="users">
                            Users
                        </MenuItemRadio>
                        <MenuItemRadio icon={<Toolbox16Regular />} name="dataItem" value="customer">
                            Customer
                        </MenuItemRadio>
                    </MenuList>
                </InlineDrawer>
                <div className={styles.content}>
                    <div>
                        {checkedValues.dataItem}
                    </div>
                </div>
            </div>
        </>
    );
};