import * as React from "react";
import {
    Home16Regular,
    PeopleTeam16Regular,
    CheckmarkCircle24Regular,
    Circle24Regular
} from "@fluentui/react-icons";
import {
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    createTableColumn,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbButton,
    BreadcrumbDivider,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { UserAvatar } from "../../components/avatar/user-avatar";

const columnSizingOptions = {
    expertname: {
        minWidth: 300,
        defaultWidth: 300,
    },
    vb: {
        minWidth: 100,
        defaultWidth: 100,
    },
    net: {
        minWidth: 100,
        defaultWidth: 100,
    },
    csharp: {
        minWidth: 100,
        defaultWidth: 100,
    },
    java: {
        minWidth: 100,
        defaultWidth: 100,
    },
    outsystem: {
        minWidth: 100,
        defaultWidth: 100,
    },
    powerapp: {
        minWidth: 100,
        defaultWidth: 100,
    },
    sharepoint: {
        minWidth: 100,
        defaultWidth: 100,
    },
    oracle: {
        minWidth: 100,
        defaultWidth: 100,
    },
    postgresql: {
        minWidth: 100,
        defaultWidth: 100,
    },
    mssql: {
        minWidth: 100,
        defaultWidth: 100,
    }
};
const items = [
    {
        expertname: { label: "Hau Len Van", status: "available" },
        vb: true,
        net: true,
        csharp: true,
        java: true,
        outsystem: false,
        powerapp: true,
        sharepoint: false,
        oracle: false,
        postgresql: true,
        mssql: true
    },
    {
        expertname: { label: "Tuan Nguyen Minh", status: "busy" },
        vb: false,
        net: true,
        csharp: true,
        java: true,
        outsystem: false,
        powerapp: false,
        sharepoint: true,
        oracle: true,
        postgresql: false,
        mssql: true
    },
    {
        expertname: { label: "Binh Vu Thanh", status: "out-of-office" },
        vb: false,
        net: true,
        csharp: false,
        java: true,
        outsystem: false,
        powerapp: true,
        sharepoint: true,
        oracle: true,
        postgresql: true,
        mssql: true
    },
    {
        expertname: { label: "Hau Len Van", status: "available" },
        vb: true,
        net: false,
        csharp: true,
        java: true,
        outsystem: false,
        powerapp: true,
        sharepoint: true,
        oracle: true,
        postgresql: false,
        mssql: true
    },
];

const columns = [
    createTableColumn({
        columnId: "expertname",
        compare: (a, b) => {
            return a.expertname.label.localeCompare(b.expertname.label);
        },
        renderHeaderCell: () => {
            return "Expert name";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout
                    media={
                        <UserAvatar
                            id={item.expertname.label}
                            displayName={item.expertname.label}
                            status={item.expertname.status}
                        />
                    }
                >
                    {item.expertname.label}
                </TableCellLayout>
            );
        }
    }),
    createTableColumn({
        columnId: "vb",
        renderHeaderCell: () => {
            return "VB";
        },

        renderCell: (item) => {
            if (item.vb) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),
    createTableColumn({
        columnId: "net",
        renderHeaderCell: () => {
            return ".NET";
        },

        renderCell: (item) => {
            if (item.net) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "csharp",
        renderHeaderCell: () => {
            return "C#";
        },

        renderCell: (item) => {
            if (item.csharp) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "java",
        renderHeaderCell: () => {
            return "Java";
        },

        renderCell: (item) => {
            if (item.java) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "outsystem",
        renderHeaderCell: () => {
            return "OutSystem";
        },

        renderCell: (item) => {
            if (item.outsystem) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "powerapp",
        renderHeaderCell: () => {
            return "PowerApp";
        },

        renderCell: (item) => {
            if (item.powerapp) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "sharepoint",
        renderHeaderCell: () => {
            return "Sharepoint";
        },

        renderCell: (item) => {
            if (item.sharepoint) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "oracle",
        renderHeaderCell: () => {
            return "Oracle";
        },

        renderCell: (item) => {
            if (item.oracle) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "postgresql",
        renderHeaderCell: () => {
            return "PostgreSQL";
        },

        renderCell: (item) => {
            if (item.postgresql) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),

    createTableColumn({
        columnId: "mssql",
        renderHeaderCell: () => {
            return "MSSQL";
        },
        renderCell: (item) => {
            if (item.mssql) {
                return <CheckmarkCircle24Regular primaryFill="green" />
            } else {
                return <Circle24Regular />
            }
        },
    }),
];

export const Expert = () => {
    const [sortState, setSortState] = React.useState({
        sortColumn: "projectName",
        sortDirection: "ascending",
    });
    const onSortChange = (e, nextSortState) => {
        setSortState(nextSortState);
    };

    return (
        <>
            <Breadcrumb aria-label="Breadcrumb default example">
                <BreadcrumbItem>
                    <BreadcrumbButton icon={<Home16Regular />}>
                        <Link to="/">Home</Link>
                    </BreadcrumbButton>
                </BreadcrumbItem>
                <BreadcrumbDivider />
                <BreadcrumbItem>
                    <BreadcrumbButton icon={<PeopleTeam16Regular />}>
                        <Link to="/expert">Expert</Link>
                    </BreadcrumbButton>
                </BreadcrumbItem>
            </Breadcrumb>
            <DataGrid
                items={items}
                columns={columns}
                sortable
                sortState={sortState}
                onSortChange={onSortChange}
                resizableColumns
                columnSizingOptions={columnSizingOptions}
                align="center"
            >
                <DataGridHeader>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                    {({ item, rowId }) => (
                        <DataGridRow key={rowId}>
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
        </>
    );
};