import React from "react";
import { Button, Image } from "@fluentui/react-components";
import { ThumbLike16Regular } from "@fluentui/react-icons";
import {
    TeachingPopover,
    TeachingPopoverSurface,
    TeachingPopoverTrigger,
} from "@fluentui/react-teaching-popover-preview";
import {
    makeStyles,
    tokens,
} from "@fluentui/react-components";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */

const reactionList = [
    {
        name: "Like",
        url: "./img/like.gif"
    }, {
        name: "Love",
        url: "./img/love.gif"
    }, {
        name: "Haha",
        url: "./img/haha.gif"
    }, {
        name: "Wow",
        url: "./img/wow.gif"
    }, {
        name: "Sad",
        url: "./img/sad.gif"
    }, {
        name: "Angry",
        url: "./img/angry.gif"
    },
]

const useStyles = makeStyles({
    container: {
        width: "fit-content",
    },
    button: {
        fontSize: tokens.fontSizeBase300
    },
    test: {
        padding: "0px"
    }
});

export const Reaction = (props) => {
    const styles = useStyles();
    return (
        <TeachingPopover className={styles.test} >
            <TeachingPopoverTrigger>
                <Button appearance="subtle" className={styles.button} icon={<ThumbLike16Regular />} size="large">Like</Button>
            </TeachingPopoverTrigger>
            <TeachingPopoverSurface className={styles.container}>
                {reactionList.map(item =>
                    <Button appearance="subtle" icon={<Image
                        alt={item.name}
                        src={item.url}
                        height={48}
                        width={48}
                    />}
                        size="large" />
                )}
            </TeachingPopoverSurface>
        </TeachingPopover>
    )
}