import React from "react";
import { Persona } from "@fluentui/react-components";
import Common from "../../common/Common";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const UserAvatarStatus = ({ key, id, displayName, status }) => {
    return (
        <Persona
            name={displayName}
            displayName={displayName}
            avatar={{ color: "colorful" }}
            secondaryText={Common.getNameStatus(status)}
            presence={{ status: status }}
        />
    )
}