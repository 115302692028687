import { Badge, Body1, Button, Caption1, Card, Checkbox, Text, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import {
    AlertUrgent16Filled,
    Attach16Regular,
    CheckmarkCircle16Regular,
    CircleHalfFill16Regular,
    Comment16Regular,
} from "@fluentui/react-icons";

import React from "react";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
const useStyles = makeStyles({
    content: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    taskNLogwork: {
        display: "grid",
        justifyContent: "space-between",
        gridTemplateColumns: "33% 33% 33%",
        '@media (max-width: 1080px)': {
            gridTemplateColumns: "100%",
        },
    },
    flex: {
        ...shorthands.gap("4px"),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    footer: {
        ...shorthands.gap("12px"),
    },
    main: {
        marginBottom: "8px"
    },
    taskCheckbox: {
        display: "flex",
        alignItems: "flex-start",
    },
})

export const TaskNLogwork = (props) => {
    const styles = useStyles();
    const calculateDaysBefore = () => {
        let num = Math.floor(Math.random() * 5) + 1
        let today = new Date();
        today.setDate(today.getDate() - num);
        let formattedDate = today.toISOString().split('T')[0];
        return formattedDate + " - Late " + num + " days";
    };

    return (
        <div className={styles.content}>
            <h5>
                Task & Logwork
            </h5>
            <div className={mergeClasses(styles.flex, styles.main)}>
                <Button>Add task</Button>
            </div>
            <div className={styles.taskNLogwork} >
                {Array.from({ length: 5 }, (_, index) => (
                    <div className={styles.main}>
                        <section className={styles.section}>
                            <Card className={styles.card}>
                                <header className={mergeClasses(styles.flex, styles.labels)}>
                                    Thực hiện xử lý xxx
                                    <Badge color="severe" shape="rounded" appearance="tint">
                                        {calculateDaysBefore()}
                                    </Badge>
                                    <Badge color="brand" shape="rounded" appearance="tint" onClick={() => alert("Show logwork detail")}>
                                        4h logged
                                    </Badge>
                                </header>

                                <div className={styles.taskCheckbox}>
                                    <Checkbox id={index + "task-1"} />

                                    <label htmlFor={index + "task-1"}>
                                        <Text block weight="semibold">
                                            Task title
                                        </Text>

                                        <Caption1 block className={styles.caption}>
                                            Donut chocolate bar oat cake. Dragée tiramisu lollipop bear claw.
                                            Marshmallow pastry jujubes toffee sugar plum.
                                        </Caption1>
                                    </label>
                                </div>
                                <div className={styles.taskCheckbox}>
                                    <Checkbox id={index + "task-2"} />

                                    <label htmlFor={index + "task-2"}>
                                        <Text block weight="semibold">
                                            Task title
                                        </Text>

                                        <Caption1 block className={styles.caption}>
                                            Donut chocolate bar oat cake. Dragée tiramisu lollipop bear claw.
                                            Marshmallow pastry jujubes toffee sugar plum.
                                        </Caption1>
                                    </label>
                                </div>

                                <footer className={mergeClasses(styles.flex, styles.footer)}>
                                    <AlertUrgent16Filled primaryFill="#C4314B" />
                                    <CircleHalfFill16Regular primaryFill="#0078DB" />

                                    <div className={styles.flex}>
                                        <Attach16Regular />
                                        <Body1>4</Body1>
                                    </div>

                                    <div className={styles.flex}>
                                        <CheckmarkCircle16Regular />
                                        <Body1>2/12</Body1>
                                    </div>
                                    <Comment16Regular />
                                    <div className={styles.flex}>
                                        <Button appearance="subtle">Add sub-task</Button>
                                        <Button appearance="subtle">Logwork</Button>
                                    </div>
                                </footer>
                            </Card>
                        </section>
                    </div>
                ))}
            </div>
        </div>
    );
};