import React from "react";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const GenerateEstimate = (props) => {
    return (
        <div>
            <h5>
                <center>Generate estimate</center>
            </h5>
        </div>
    );
};