import { Link } from "@fluentui/react-components";
import React from "react";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const Unauthorized = (props) => {
    return (
        <div id="home-page">
            <h5>
                <center>
                    The client request has not been completed because it lacks valid authentication credentials for the requested resource.
                    <br />
                    <Link href={process.env.REACT_APP_LOCAL}>
                        Back to homepage
                    </Link>
                </center>
            </h5>
        </div>
    );
};