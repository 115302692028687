import * as React from "react";
import {
    DocumentRegular,
    Add24Regular,
} from "@fluentui/react-icons";
import { Home16Regular, Box16Regular } from "@fluentui/react-icons";
import {
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    createTableColumn,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbButton,
    BreadcrumbDivider,
    Button,
    AvatarGroup,
    AvatarGroupItem,
    AvatarGroupPopover,
    partitionAvatarGroupItems,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { UserAvatar } from "../../components/avatar/user-avatar";


const items = [
    {
        projectName: { label: "AKK", icon: <DocumentRegular />, url: "sample-1" },
        projectLead: { label: "Hau Len Van", status: "available" },
        type: "VB6 to VB.NET",
        customer: "Toyota",
        lastUpdated: { label: "7h ago", timestamp: 1 },
        teamMember: [
            "Hau Len Van"
        ],
    },
    {
        projectName: { label: "Black", icon: <DocumentRegular />, url: "sample-2" },
        projectLead: { label: "Tuan Nguyen Minh", status: "busy" },
        type: "Cobol to Cobol.NET",
        customer: "Honda",
        lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
        teamMember: [
            "Tuan Nguyen Minh",
            "Hau Len Van",
            "Tu Tran The"
        ],
    },
    {
        projectName: { label: "Rotto", icon: <DocumentRegular />, url: "sample-3" },
        projectLead: { label: "Binh Vu Thanh", status: "out-of-office" },
        type: "Java Version up",
        customer: "Meicom",
        lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
        teamMember: [
            "Binh Vu Thanh",
            "Dan Nguyen Dinh"
        ],
    },
    {
        projectName: { label: "NTT2024", icon: <DocumentRegular />, url: "sample-4" },
        projectLead: { label: "Hau Len Van", status: "available" },
        type: "SP 2007 to SPO",
        customer: "NTT Data",
        lastUpdated: { label: "Tue at 9:30 AM", timestamp: 3 },
        teamMember: [
            "Hau Len Van",
            "Truong Pham Hong"
        ],
    },
];

const columns = [
    createTableColumn({
        columnId: "projectName",
        compare: (a, b) => {
            return a.projectName.label.localeCompare(b.projectName.label);
        },
        renderHeaderCell: () => {
            return "Project Name";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout media={item.projectName.icon}>
                    <Link to={"/project/" + item.projectName.url + "/general"}>{item.projectName.label}</Link>
                </TableCellLayout>
            );
        },
    }),
    createTableColumn({
        columnId: "projectLead",
        compare: (a, b) => {
            return a.projectLead.label.localeCompare(b.projectLead.label);
        },
        renderHeaderCell: () => {
            return "Project Lead";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout
                    media={
                        <UserAvatar
                            id={item.projectLead.label}
                            displayName={item.projectLead.label}
                            status={item.projectLead.status}
                        />
                    }
                >
                    {item.projectLead.label}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn({
        columnId: "type",
        compare: (a, b) => {
            return a.type.localeCompare(b.type);
        },
        renderHeaderCell: () => {
            return "Type";
        },

        renderCell: (item) => {
            return item.type;
        },
    }),
    createTableColumn({
        columnId: "customer",
        compare: (a, b) => {
            return a.customer.localeCompare(b.customer);
        },
        renderHeaderCell: () => {
            return "Customer";
        },

        renderCell: (item) => {
            return item.customer;
        },
    }),
    createTableColumn({
        columnId: "lastUpdated",
        compare: (a, b) => {
            return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
        },
        renderHeaderCell: () => {
            return "Last updated";
        },

        renderCell: (item) => {
            return item.lastUpdated.label;
        },
    }),
    createTableColumn({
        columnId: "teamMember",
        compare: (a, b) => {
            return a.teamMember.label.localeCompare(b.teamMember.label);
        },
        renderHeaderCell: () => {
            return "Team Member";
        },
        renderCell: (item) => {
            const partitionedItems = partitionAvatarGroupItems({ items: item.teamMember, layout: "pie" });
            return (
                <>
                    <AvatarGroup layout="pie">
                        {partitionedItems.inlineItems.map((name) => (
                            <AvatarGroupItem name={name} key={name} />
                        ))}
                        {partitionedItems.overflowItems && (
                            <AvatarGroupPopover>
                                {partitionedItems.overflowItems.map((name) => (
                                    <AvatarGroupItem name={name} key={name} />
                                ))}
                            </AvatarGroupPopover>
                        )}
                    </AvatarGroup>
                </>
            );
        },
    }),
];

export const ProjectList = () => {
    const [sortState, setSortState] = React.useState({
        sortColumn: "projectName",
        sortDirection: "ascending",
    });
    const onSortChange = (e, nextSortState) => {
        setSortState(nextSortState);
    };

    return (
        <>
            <Breadcrumb aria-label="Breadcrumb default example">
                <BreadcrumbItem>
                    <BreadcrumbButton icon={<Home16Regular />}>
                        <Link to="/">Home</Link>
                    </BreadcrumbButton>
                </BreadcrumbItem>
                <BreadcrumbDivider />
                <BreadcrumbItem>
                    <BreadcrumbButton icon={<Box16Regular />}>
                        <Link to="/project-list">Project</Link>
                    </BreadcrumbButton>
                </BreadcrumbItem>
            </Breadcrumb>
            <Button icon={<Add24Regular />} >  Create new project </Button>
            <DataGrid
                items={items}
                columns={columns}
                sortable
                sortState={sortState}
                onSortChange={onSortChange}
            >
                <DataGridHeader>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                    {({ item, rowId }) => (
                        <DataGridRow key={rowId}>
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
        </>
    );
};