import React, { useState } from "react";
import { Divider, MenuItemCheckbox, MenuList } from "@fluentui/react-components";
import {
    bundleIcon,
    CutRegular,
    CutFilled,
    ClipboardPasteRegular,
    ClipboardPasteFilled,
    EditRegular,
    EditFilled,
} from "@fluentui/react-icons";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft24Regular } from "@fluentui/react-icons";

const CutIcon = bundleIcon(CutFilled, CutRegular);
const PasteIcon = bundleIcon(ClipboardPasteFilled, ClipboardPasteRegular);
const EditIcon = bundleIcon(EditFilled, EditRegular);
/**
 * Header components for website
 * @param props 
 */

export const Header = (props) => {
    const [checkedValues, setCheckedValues] = useState({ edit: ["cut", "paste"], customer: ["meicom"] });
    const onChange = (
        e,
        { name, checkedItems }
    ) => {
        setCheckedValues((s) => {
            return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems };
        });
    };

    const { id } = useParams();

    return (
        <>
            <Link to={"/project/" + id +"/general"}><ArrowLeft24Regular /> Back</Link>
            <MenuList checkedValues={checkedValues} onCheckedValueChange={onChange}>
                <Divider>Text</Divider>
                <MenuItemCheckbox checkmark={true} icon={<CutIcon />} name="edit" value="cut">
                    Cut
                </MenuItemCheckbox>
                <MenuItemCheckbox icon={<PasteIcon />} name="edit" value="paste">
                    Paste
                </MenuItemCheckbox>
                <MenuItemCheckbox icon={<EditIcon />} name="edit" value="edit">
                    Edit
                </MenuItemCheckbox>
                <Divider>Tên khách hàng</Divider>
                <MenuItemCheckbox checkmark={true} icon={<CutIcon />} name="customer" value="meicom">
                    Meicom
                </MenuItemCheckbox>
                <MenuItemCheckbox icon={<PasteIcon />} name="customer" value="flt">
                    FLT
                </MenuItemCheckbox>
                <MenuItemCheckbox icon={<EditIcon />} name="customer" value="nttd">
                    NTTD
                </MenuItemCheckbox>
            </MenuList>
        </>
    );
};